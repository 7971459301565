"use client"

import {Button, Link} from "@/components/ButtonLink"
import {useTranslations} from "next-intl"
import {$path} from "next-typesafe-url"

const GlobalErrorPage = ({error, reset}: {error: Error & {digest?: string}; reset: () => void}) => {
  const t = useTranslations("Error")
  return (
    <div className="col-span-2 row-span-3 flex flex-col items-center justify-center">
      <div className="flex flex-col gap-2 rounded border border-gray-300 p-4 shadow">
        <span className="text-xl">{t("error")}</span>
        <span>{t("try-again")}</span>
        <span>{t("support")}</span>
        {error.digest && (
          <span>
            {t("digest")} {error.digest}
          </span>
        )}
        <div className="grid auto-cols-fr grid-flow-col grid-rows-1 gap-2">
          <Button intent="primary" onClick={reset}>
            {t("retry")}
          </Button>
          <Link intent="primary" href={$path({route: "/login"})}>
            {t("login")}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default GlobalErrorPage
